import React, { useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ConfirmReadAfterSubmitImage from '@assets/confirm-savings-requested.svg';

import { COMPILATION_SUFFIX_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import { FormSection, NumericFormField, RadioFormField } from '@components/atoms/form';
import { AverageEconomyPerYear, ProposalCompilationFormData } from '@components/molecules/start-compilation/types';
import AveragePriceDisplay from '@components/molecules/start-compilation/average-price-display';

import { Bid, Proposal } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id/types';

import {
  calculateCommissionByCustomerEngagedLevel,
  customerEngagementLevelOptions,
  getFirstSupplyDateFromProposal,
} from './form-helpers';
import { CommissionModalityTypeEnum } from '@utils/translators/proposal';

const calculateAveragesFromBids = (
  proposalPeriodWindow: number,
  bids: Pick<Bid, 'economy'>[],
  firstSupplyDate: Date,
): AverageEconomyPerYear[] => {
  /*
   * Whats the idea behind this algorithm?
   * 1. Initialize the hashmap with the years from `proposalPeriodWindow` as the keys and
   *    the values as arrays of numbers containing the amounts found in the bids objects.
   * 2. Iterate over each bid economy per year value and push it into the array of the corresponding
   *    year found in the hashmap.
   * 3. At the end of the process, sum the years of the non empty arrays in the hashmap. For the
   *    empty arrays, return NaN (to represent that there were no amounts from bids for the year
   *    requested in the `proposalPeriodWindow`).
   */

  const cumulativeAmountsPerYear: { [year: number]: number[] } = {};
  [...Array(proposalPeriodWindow).keys()].forEach((_, index) => {
    cumulativeAmountsPerYear[firstSupplyDate.getFullYear() + index] = [];
  });

  bids.forEach((bid) => {
    bid.economy.forEach(({ year, amount }) => {
      if (year in cumulativeAmountsPerYear) {
        cumulativeAmountsPerYear[year].push(amount);
      }
    });
  });

  return Object.keys(cumulativeAmountsPerYear).map((year) => {
    const parsedYear = parseInt(year);
    const amountsPerYearArray = cumulativeAmountsPerYear[parsedYear];
    const amountAverage =
      amountsPerYearArray.length === 0
        ? NaN
        : amountsPerYearArray.reduce((total, value) => total + value, 0) / amountsPerYearArray.length;
    return {
      year: parsedYear,
      amount: amountAverage,
    };
  });
};

interface ThirdStepProps {
  proposal: Proposal;
}
const ThirdStep: React.FC<ThirdStepProps> = ({ proposal }) => {
  const isFixedPrice = proposal.contractType === 'FIXED_PRICE';
  const placeholder = isFixedPrice ? 'R$' : '%';
  const prefix = isFixedPrice ? 'R$ ' : undefined;
  const suffix = isFixedPrice ? undefined : '%';
  const decimalScale = isFixedPrice ? 2 : 0;

  const contractTypeDisplay = isFixedPrice ? 'Preço' : 'Desconto';

  const { getValues, watch, setValue } = useFormContext<ProposalCompilationFormData>();

  const customerEngagementLevel = watch('customerEngagementLevel');

  // NOTE: (30-08-2022 -> Task 9YVA3) Only working with a single contract duration window until Kaio comes up with a
  // display design that covers multiple windows
  const proposalPeriodWindow = proposal.periods[0];
  const bidsInsideScope = Object.values(getValues('bidCategorization.bidsInsideScope'));
  const firstSupplyDate = getFirstSupplyDateFromProposal(proposal.units);
  const averageRetailEntries = calculateAveragesFromBids(
    proposalPeriodWindow,
    bidsInsideScope.filter((bid) => bid.retailService),
    firstSupplyDate,
  );
  const averageWholesaleEntries = calculateAveragesFromBids(
    proposalPeriodWindow,
    bidsInsideScope.filter((bid) => !bid.retailService),
    firstSupplyDate,
  );

  const averageAllBids = calculateAveragesFromBids(proposalPeriodWindow, proposal.bids, firstSupplyDate);

  const setCommissionByCommissionModality = (commissionModality: keyof typeof CommissionModalityTypeEnum) => {
    setValue(
      `taxesAndCommissions.${commissionModality !== 'WHOLESALER' ? 'retail' : 'wholesale'}Commission`,
      calculateCommissionByCustomerEngagedLevel(
        proposal.commissionAnalysis?.suggestedCommission ?? 0,
        customerEngagementLevel ?? 'VERY_HIGH',
      ),
    );
  };

  useEffect(() => {
    setCommissionByCommissionModality(proposal.commissionModality);
  }, []);

  useEffect(() => {
    setCommissionByCommissionModality(proposal.commissionModality);
  }, [customerEngagementLevel, proposal]);
  return (
    <div className="py-7">
      <div className="space-y-8">
        <div className="space-y-6">
          <div className="space-y-2">
            <h3 className="font-bold text-heading-xsmall">{`${contractTypeDisplay} médio das propostas`}</h3>
            <p className="text-paragraph-medium text-neutral-50">{`Abaixo separamos o ${contractTypeDisplay.toLowerCase()} médio levando em consideração o tipo de comercializadora, para ser estipulado em seguida a Comissão da Clarke.`}</p>
          </div>

          <div className="flex flex-col space-y-5">
            <AveragePriceDisplay {...{ proposal, traderType: 'retail', averageEntries: averageRetailEntries }} />
            <AveragePriceDisplay {...{ proposal, traderType: 'wholesale', averageEntries: averageWholesaleEntries }} />
            <AveragePriceDisplay {...{ proposal, traderType: 'both', averageEntries: averageAllBids }} />
          </div>
        </div>
        {/* Engagement level */}
        <div className="grid grid-cols-1 gap-8 xl:grid-cols-3">
          <FormSection
            title="Nível de engajamento do cliente"
            subtitle='Essa régua descreve o quanto o cliente está "comprado" com a solução da Clarke'
          />
          <div className="grid grid-cols-1 col-span-2 gap-5">
            <RadioFormField<ProposalCompilationFormData>
              field="customerEngagementLevel"
              className="sm:flex-row sm:gap-6"
              inputOptions={customerEngagementLevelOptions}
            />
          </div>
        </div>

        {/* Commission */}
        <div className="grid grid-cols-1 gap-8 xl:grid-cols-3">
          <FormSection
            title="Comissão da Clarke"
            subtitle={`Utilize o ${contractTypeDisplay.toLowerCase()} médio acima para estipular quanto ficará a comissão da Clarke.`}
          />
          <div className="grid grid-cols-2 col-span-2 gap-5">
            <NumericFormField<ProposalCompilationFormData>
              field="taxesAndCommissions.retailCommission"
              id="retail-commission"
              label="Comissão varejista"
              formatProps={{
                placeholder,
                prefix,
                suffix,
                fixedDecimalScale: true,
                decimalScale,
              }}
              options={{
                validate: (v) => {
                  const hasRetailService = proposal.bids.some((bid) => bid.retailService);
                  const isValueValid = !isNaN(v);
                  return (
                    !hasRetailService ||
                    isValueValid ||
                    'Há oferta(s) varejista(s) para essa proposta. Defina o valor de comissão'
                  );
                },
              }}
            />
            <NumericFormField<ProposalCompilationFormData>
              field="taxesAndCommissions.wholesaleCommission"
              id="wholesale-commission"
              label="Comissão atacadista"
              formatProps={{
                placeholder,
                prefix,
                suffix,
                fixedDecimalScale: true,
                decimalScale,
              }}
              options={{
                validate: (v) => {
                  const hasWholesaleService = proposal.bids.some((bid) => !bid.retailService);
                  const isValueValid = !isNaN(v);
                  return (
                    !hasWholesaleService ||
                    isValueValid ||
                    'Há oferta(s) atacadista(s) para essa proposta. Defina o valor de comissão'
                  );
                },
              }}
            />
          </div>
        </div>

        {/* Taxes */}
        <div className="grid grid-cols-1 gap-8 xl:grid-cols-3">
          <FormSection title="Encargos" subtitle="Seção das premissas referentes aos encargos." />

          <div className="grid grid-cols-2 col-span-2 gap-5">
            <NumericFormField<ProposalCompilationFormData>
              field="taxesAndCommissions.essAndEer"
              id="ess-eer"
              label="Liquidação, ESS e EER"
              formatProps={{
                placeholder: 'R$/MWh',
                prefix: undefined,
                suffix: 'R$/MWh',
                fixedDecimalScale: true,
                decimalScale,
              }}
              options={{
                validate: (v) => {
                  const isModulationDefined = !isNaN(getValues('taxesAndCommissions.modulation'));
                  const isValueValid = !isNaN(v);
                  if (isModulationDefined) {
                    return isValueValid || 'É preciso definir o valor de ESS/EER se houver modulação';
                  }
                  return true;
                },
              }}
            />

            <NumericFormField<ProposalCompilationFormData>
              field="taxesAndCommissions.modulation"
              id="modulation"
              label="Modulação"
              formatProps={{
                placeholder: 'R$/MWh',
                prefix: undefined,
                suffix: 'R$/MWh',
                fixedDecimalScale: true,
                decimalScale,
              }}
              options={{
                validate: (v) => {
                  const isEssDefined = !isNaN(getValues('taxesAndCommissions.essAndEer'));
                  const isValueValid = !isNaN(v);
                  if (isEssDefined) {
                    return isValueValid || 'É preciso definir o valor de modulação se houver ESS/EER';
                  }
                  return true;
                },
              }}
            />

            <NumericFormField<ProposalCompilationFormData>
              field="taxesAndCommissions.managementFee"
              id="management-fee"
              label="Custo de Gestão por unidade"
              formatProps={{
                placeholder,
                prefix,
                suffix,
                fixedDecimalScale: true,
                decimalScale,
              }}
              options={{
                required: { value: true, message: 'Defina o valor de gestão da Clarke' },
              }}
            />

            <NumericFormField<ProposalCompilationFormData>
              field="taxesAndCommissions.initialExpenses"
              id="initial-expenses"
              label="Investimento inicial por unidade"
              formatProps={{
                placeholder,
                prefix,
                suffix,
                fixedDecimalScale: true,
                decimalScale,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;

interface AfterSavingsSubmissionDialogProps {
  proposal: Proposal;
}

export const AfterSavingsSubmissionDialog: React.FC<AfterSavingsSubmissionDialogProps> = ({ proposal }) => {
  const navigate = useNavigate();
  const [isNavigateToResultsEnabled, setIsNavigateToResultsEnabled] = React.useState<boolean>(false);
  const confirmButtonRef = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    window.setTimeout(() => setIsNavigateToResultsEnabled(true), 2000);
  }, []);

  return (
    <Dialog
      initialFocus={confirmButtonRef}
      open={true}
      onClose={() => {
        return;
      }}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="flex fixed inset-0 justify-center items-center p-4 max-w-[60%] mx-auto">
        <Dialog.Panel data-cy="confirm-after-submission-dialog" className="bg-white border border-black">
          <div className="flex flex-col items-center p-8 space-y-8">
            <div className="flex flex-col items-center space-y-4">
              <img src={ConfirmReadAfterSubmitImage} alt="" className="mx-auto" />
              <h1 className="font-bold text-center text-heading-2xlarge">
                Informações salvas, estamos fazendo os cálculos!
              </h1>
              <p className="text-center text-paragraph-medium text-neutral-50">
                O cálculo demora alguns minutos para ser feito, por isso deixamos as máquinas calculando em segundo
                plano. Você poderá ver e exportar o Compilado de Propostas no painel do cliente em breve.
              </p>
            </div>

            <button
              ref={confirmButtonRef}
              data-cy="confirm-after-submission-button"
              type="button"
              disabled={!isNavigateToResultsEnabled}
              onClick={() => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${COMPILATION_SUFFIX_PATH}`)}
              className="py-4 px-6 border border-black bg-primary-60 text-paragraph-large rounded-pill hover:bg-primary-70 active:bg-primary-80 disabled:bg-neutral-10 disabled:text-neutral-50"
            >
              {`Ir para ${proposal.group.name}`}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
