import React from 'react';
import { Controller, FieldPath, RegisterOptions, useFormContext } from 'react-hook-form';
import { Select } from '@clarke-energia/foton';
import get from 'lodash.get';

export interface SelectFormFieldProps<T> {
  field: FieldPath<T>;
  label: string;
  placeholder?: string;
  id: string;
  inputOptions: { optionLabel: string; value: string }[];
  options?: RegisterOptions;
  transformOutput?: (value: string) => any;
}

export function SelectFormField<T>({
  field,
  label,
  id,
  inputOptions,
  placeholder,
  options,
  transformOutput,
}: SelectFormFieldProps<T>) {
  const { control } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value, ref }, formState }) => {
        const errorMessage = get(formState.errors, `${field}.message`);

        return (
          <Select
            id={id}
            customAttrs={{ 'data-cy': id }}
            name={name}
            label={label}
            options={inputOptions.map(({ optionLabel, value }, _) => ({
              display: optionLabel,
              value: value,
            }))}
            // @ts-expect-error :: because value is supposed to be read-only
            value={typeof value === 'string' ? value : value === null || value === undefined ? '' : value.toString()}
            placeholder={placeholder || 'Selecione uma opção'}
            ref={ref}
            required={(options?.required as boolean) || false}
            error={errorMessage}
            onBlur={onBlur}
            onChange={(e) => onChange(transformOutput ? transformOutput(e.target.value) : e.target.value)}
          />
        );
      }}
    />
  );
}
