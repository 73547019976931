import React from 'react';
import { DeepPartial } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { requestSavingsCalculation } from '@services/rest/request-savings-calculation';

import { COMPILATION_SUFFIX_PATH, DOES_NOT_EXIST_PATH, VIEW_PROPOSAL_LIST_PATH } from '@routers/constants';

import { getCurrentProposalFromProcess } from '@components/organisms/proposal-round-header';
import { ProposalCompilationFormData } from '@components/molecules/start-compilation/types';
import CompilationForm from '@components/organisms/start-compilation/form';
import {
  parseBidsIntoFormScopeData,
  parseFormDataIntoPayloadData,
  parseUnitIntoDemandAndConsumptionData,
} from '@components/organisms/start-compilation/form-helpers';
import LoadingPage from '@components/layout/loading-page';

import useAuth from '@hooks/auth';
import { useGetProcessAndGroupByProposalId } from '@hooks/process/queries/get-process-and-group-data-by-proposal-id';

const StartCompilationPage: React.FC = () => {
  const navigate = useNavigate();
  const { proposalId } = useParams();
  const {
    authStatus: { accessToken },
  } = useAuth();

  if (!proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  const { processData, fetchProcessData, isProcessDataLoading } = useGetProcessAndGroupByProposalId();

  React.useEffect(() => {
    if (proposalId) {
      fetchProcessData(proposalId);
    }
  }, [proposalId]);

  const proposal = getCurrentProposalFromProcess(processData, proposalId);

  const cancelFormCallback = () => navigate(`${VIEW_PROPOSAL_LIST_PATH}/${proposal.id}/${COMPILATION_SUFFIX_PATH}`);

  const initialValues: DeepPartial<ProposalCompilationFormData> = {
    bidCategorization: parseBidsIntoFormScopeData(proposal.bids),
    demandAndConsumption: proposal.units.map((unit) => parseUnitIntoDemandAndConsumptionData(unit)),
    taxesAndCommissions: {
      retailCommission: 0,
      wholesaleCommission: 0,
      essAndEer: NaN,
      modulation: NaN,
      managementFee: NaN,
    },
    customerEngagementLevel: 'VERY_HIGH',
  };

  const onSubmit = async ({
    taxesAndCommissions: { retailCommission, wholesaleCommission, ...taxes },
    ...data
  }: ProposalCompilationFormData) => {
    await Promise.all(
      Object.values(data.bidCategorization.bidsInsideScope).map(async (bid) => {
        const payloadWithCommissions = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: true },
          group: proposal.group,
          units: proposal.units,
          formData: {
            taxesAndCommissions: { retailCommission, wholesaleCommission, ...taxes },
            ...data,
          },
        });

        const payloadWithoutCommissions = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: true },
          group: proposal.group,
          units: proposal.units,
          formData: {
            taxesAndCommissions: { retailCommission: null, wholesaleCommission: null, ...taxes },
            ...data,
          },
        });

        await requestSavingsCalculation(payloadWithCommissions, accessToken);
        await requestSavingsCalculation(payloadWithoutCommissions, accessToken);
      }),
    );
    await Promise.all(
      Object.values(data.bidCategorization.bidsOutsideScope).map(async (bid) => {
        const payloadWithCommissions = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: false },
          group: proposal.group,
          units: proposal.units,
          formData: {
            taxesAndCommissions: { retailCommission, wholesaleCommission, ...taxes },
            ...data,
          },
        });

        const payloadWithoutCommissions = parseFormDataIntoPayloadData({
          bidScope: { bid, isInsideScope: false },
          group: proposal.group,
          units: proposal.units,
          formData: {
            taxesAndCommissions: { retailCommission: null, wholesaleCommission: null, ...taxes },
            ...data,
          },
        });
        await requestSavingsCalculation(payloadWithCommissions, accessToken);
        await requestSavingsCalculation(payloadWithoutCommissions, accessToken);
      }),
    );
  };

  return isProcessDataLoading ? (
    <LoadingPage />
  ) : (
    <>
      <CompilationForm {...{ proposal, initialValues, onFormSubmitCallback: onSubmit, cancelFormCallback }} />
    </>
  );
};
export default StartCompilationPage;
